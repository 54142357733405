import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {AppRoutingModule} from "./app-routing.module";
import { BogotacustomComponent } from './page/bogotacustom/bogotacustom.component';
import { BogotaComponent } from './page/bogota/bogota.component';
import { CaliComponent } from './page/cali/cali.component';

@NgModule({
  declarations: [
    AppComponent,
    BogotacustomComponent,
    BogotaComponent,
    CaliComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTableModule,
    HttpClientModule,
    AppRoutingModule,
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
