import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import { BogotaComponent } from './page/bogota/bogota.component';
import { BogotacustomComponent } from './page/bogotacustom/bogotacustom.component';
import {CaliComponent} from "./page/cali/cali.component";

const routes: Routes = [
    {
      path: 'bogota',
      component: BogotaComponent
    },
    {
      path: 'bogota/custom',
      component: BogotacustomComponent
    },
    {
      path: 'cali',
      component: CaliComponent
    },
    {path: '**', redirectTo: '/cali'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
