import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/services/app.service';

export interface TurnElement {
  unit_name: string,
  unit_id_wia: string,
  name_zone: string,
  pos_time: string,
  speed: string,
  location: string,
}

@Component({
  selector: 'app-bogotacustom',
  templateUrl: './bogotacustom.component.html',
  styleUrls: ['./bogotacustom.component.css']
})
export class BogotacustomComponent implements OnInit {

  title = 'turnos';
  table1: string | undefined;
  last_update: Date | undefined;
  date: string | null = null;
  datas: TurnElement[] = [];
  updateRef: any;

  constructor(private datePipe: DatePipe, private appService: AppService, private cd: ChangeDetectorRef,) {}

  ngOnInit(): void {
    let date = new Date();
    this.date = this.datePipe.transform(date,"yyyy-MM-dd");
    console.log(this.date);
    this.getDatasCustom();
    this.updateRef = setInterval(() => {
      this.getDatasCustom();
    }, 30000);
    this.table1 = 'Enturnados ' + this.date;
  }

  getDatasCustom(){
    this.appService.getDatasCustom().then((messages) => {
      console.log("llegaaa");
      if(messages.count > 0){
        this.datas = messages.result;
        console.log("llegaaa");
        console.log(this.datas);
        
      }
    });
    this.last_update = new Date();
    this.cd.detectChanges();
  }

}
