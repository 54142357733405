import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any = null;

    constructor(
        private router: Router,
        private http: HttpClient
    ) {}

    async getDatas() {
        let data = await this.http.get<any>('/api/turnos/data').toPromise();
        if (data.count > 0) {
            return data;
        } else {
            return null;
        }
    }
    async getDatasCustom() {
        let data = await this.http.get<any>('/api/turnos/data/custom').toPromise();
        if (data.count > 0) {
            return data;
        } else {
            return null;
        }
    }
    async getDatasCali() {
      let data = await this.http.get<any>('/api/cali/data').toPromise();
      console.log(data);
      if (data.count > 0) {
        return data;
      } else {
        return null;
      }
    }


}
