import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/services/app.service';


export interface TurnElement {
  unit_name: string,
  unit_id_wia: string,
  name_zone: string,
  pos_time: string,
  speed: string,
  location: string,
}

@Component({
  selector: 'app-bogota',
  templateUrl: './bogota.component.html',
  styleUrls: ['./bogota.component.css']
})
export class BogotaComponent implements OnInit {

  title = 'turnos';
  table1: string | undefined;
  last_update: Date | undefined;
  date: string | null = null;
  datas: TurnElement[] = [];
  updateRef: any;

  constructor(private datePipe: DatePipe, private appService: AppService, private cd: ChangeDetectorRef,) {}

  ngOnInit(){
    let date = new Date();
    this.date = this.datePipe.transform(date,"yyyy-MM-dd");
    console.log(this.date);
    this.getDatas();
    this.updateRef = setInterval(() => {
      this.getDatas();
    }, 30000);
    this.table1 = 'Enturnados ' + this.date;
  }

  getDatas(){
    this.appService.getDatas().then((messages) => {
      console.log(messages.result);
      if(messages.count > 0){
        this.datas = messages.result;
      }
    });
    this.last_update = new Date();
    this.cd.detectChanges();
  }

}
