<div class="container">
  <div class="logo">
    <div class="logogps">
      <img src="assets/img/gpscontrol.jpg" />
    </div>
    <div class="logosiglo">
      <img src="assets/img/sigloxxi.jpg" />
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <ng-container *ngIf="last_update">
        <p>
          Ultima actualizacion:
          {{ last_update | date : "dd MMM yyyy hh:mm:ss a" }}
        </p>
      </ng-container>
      <h1 class="sub-title">{{ table1 }}</h1>
      <div class="table-responsive">
        <table
          class="table table-striped table-bordered table-hover"
          style="background-color: #fff"
        >
          <tr style="background-color: #005ea0; color: #fff">
            <th style="color: #fff !important">
              <h4><b>#</b></h4>
            </th>
            <th>NÚMERO DEL MOVIL</th>
            <th>ESTADO</th>
            <th>FECHA Y HORA DE LLEGADA</th>
            <th>OBSERVACIONES</th>
          </tr>
          <ng-container *ngIf="turned.length == 0">
            <b style="color: #8c8c8c">No hay datos.</b>
          </ng-container>
          <ng-container *ngIf="turned">
            <ng-container *ngFor="let data of turned; let i = index">
              <tr>
                <td style="background-color: #005ea0; color: #fff">
                  <b style="color: #ccc">{{ i + 1 }}</b>
                </td>
                <td>
                  <span style="color: #8c8c8c">{{ data.unit_name }}</span>
                </td>
                <td>
                  <ng-container *ngIf="data.status === 'error'">
                    <span>
                      <b style="color: #aa0000">Recuperado con error</b>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="data.status === 'turned'">
                    <span>
                      <b style="color: #005ea0">En parqueadero</b>
                    </span>
                  </ng-container>
                </td>
                <td>
                  <span>{{ data.pos_time }}</span>
                </td>
                <td>
                  <ng-container
                    *ngIf="data.penalized"
                  >
                    <span>
                      <b style="color: #aa0000"
                        >+10 min de multa por retraso en el ingreso</b
                      >
                    </span>
                  </ng-container>
                  <ng-container *ngIf="data.speed_penalized > 80">
                    <span>
                      <b style="color: #aa0000"
                        >+10 min de multa por exceso de velocidad
                        {{ data.speed_penalized }} km/h
                      </b>
                    </span>
                  </ng-container>
                  <ng-container *ngIf="data.status === 'error'">
                    <span>
                      <b style="color: #aa0000"
                        >La unidad tuvo un error, pero se enturnó
                        satisfactoriamente (Recuperado)</b
                      >
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="data.status === 'turned' && !data.penalized && data.speed_penalized <= 80"
                  >
                    <span>
                      <b style="color: #005ea0">-</b>
                    </span>
                  </ng-container>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <h1 class="sub-title">{{ table3 }}</h1>
      <div class="table-responsive">
        <table
          class="table table-striped table-bordered table-hover"
          style="background-color: #fff"
        >
          <tr style="background-color: #aa0000; color: #fff">
            <th style="color: #fff !important">
              <h4><b>#</b></h4>
            </th>
            <th>NÚMERO DEL MOVIL</th>
            <th>ESTADO</th>
            <th>RAZON</th>
            <th>ZONA</th>
            <th>FECHA Y HORA DE LLEGADA</th>
          </tr>
          <ng-container *ngIf="infractors.length == 0">
            <b style="color: #8c8c8c">No hay datos.</b>
          </ng-container>
          <ng-container *ngIf="infractors">
            <ng-container *ngFor="let data of infractors; let i = index">
              <tr>
                <td style="background-color: #aa0000; color: #fff">
                  <b style="color: #ccc">{{ i + 1 }}</b>
                </td>
                <td>
                  <span style="color: #8c8c8c">{{ data.unit_name }}</span>
                </td>
                <td>
                  <ng-container *ngIf="data.penalized || data.speed_penalized">
                    <span>
                      <b style="color: #aa0000">Penalizado</b>
                    </span>
                  </ng-container>
                </td>
                <td>
                  <ng-container
                    *ngIf="data.speed_penalized > 80"
                  >
                    <span>
                      <b style="color: #aa0000"
                        >Exceso de velocidad {{ data.speed_penalized }} km/h</b
                      >
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="data.penalized"
                  >
                    <span>
                      <b style="color: #aa0000">Retraso en el ingreso</b>
                    </span>
                  </ng-container>
                </td>
                <td>
                  <span>{{ data.name_zone }}</span>
                </td>
                <td>
                  <span>
                    <b style="color: #aa0000">{{ data.pos_time }}</b>
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 text-center">
      <h1 class="sub-title">{{ table2 }}</h1>
      <div class="table-responsive">
        <table
          class="table table-striped table-bordered table-hover"
          style="background-color: #fff"
        >
          <tr style="background-color: #1aaa00; color: #fff">
            <th style="color: #fff !important">
              <h4><b>#</b></h4>
            </th>
            <th>NÚMERO DEL MOVIL</th>
            <th>ESTADO</th>
            <th>ZONA</th>
            <th>FECHA Y HORA DE INICIO</th>
          </tr>
          <ng-container *ngIf="incoming.length == 0">
            <b style="color: #8c8c8c">No hay datos.</b>
          </ng-container>
          <ng-container *ngIf="incoming">
            <ng-container *ngFor="let data of incoming; let i = index">
              <tr>
                <td style="background-color: #1aaa00; color: #fff">
                  <b style="color: #ccc">{{ i + 1 }}</b>
                </td>
                <td>
                  <span style="color: #8c8c8c">{{ data.unit_name }}</span>
                </td>
                <td>
                  <ng-container *ngIf="data.status === 'error'">
                    <span>
                      <b style="color: #1aaa00">Recuperado con error</b>
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="data.status === 'incoming' && data.speed_penalized <= 80"
                  >
                    <span>
                      <b style="color: #8c8c8c">En camino</b>
                    </span>
                  </ng-container>
                  <ng-container
                    *ngIf="data.status === 'incoming' && data.speed_penalized > 80"
                  >
                    <span>
                      <b style="color: #aa0000">En camino con penalizacion</b>
                    </span>
                  </ng-container>
                </td>
                <td>
                  <span>{{ data.name_zone }}</span>
                </td>
                <td>
                  <span>
                    <b style="color: #1aaa00">{{ data.pos_time }}</b>
                  </span>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
</div>
