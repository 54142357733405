import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppService } from 'src/app/services/app.service';


export interface TurnElementCali {
  status: string,
  penalized: boolean,
  unit_name: string,
  unit_id_wia: string,
  name_zone: string,
  pos_time: string,
  speed: string,
  speed_val: number,
  speed_penalized: number,
  location: string,
}

@Component({
  selector: 'app-cali',
  templateUrl: './cali.component.html',
  styleUrls: ['./cali.component.css']
})
export class CaliComponent implements OnInit {

  table1: string | undefined;
  table2: string | undefined;
  table3: string | undefined;
  date: string | null = null;
  last_update: Date | undefined;
  updateRef: any;
  incoming: TurnElementCali[] = [];
  infractors: TurnElementCali[] = [];
  turned: TurnElementCali[] = [];
  all_data: TurnElementCali[] = [];

  constructor(
    private dataPipe: DatePipe,
    private appService: AppService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    let date = new Date();
    this.date = this.dataPipe.transform(date,  "yyyy-MM-dd");
    console.log(this.date);
    this.getDatas();
    this.updateRef = setInterval(() => {
      this.getDatas();
    }, 30000);
    this.table1 = 'Enturnados ' + this.date;
    this.table2 = 'En Camino ' + this.date;
    this.table3 = 'Infractores ' + this.date;
  }
  getDatas(){
    this.appService.getDatasCali().then((messages) => {
      console.log(messages.result);
      if (messages.count > 0){
        this.turned = []
        this.all_data = messages.result;
        this.incoming = messages.result.filter((message: { status: string; }) => message.status === 'incoming');
        this.infractors = messages.result.filter((message: { penalized: boolean, speed_penalized: number }) => { return message.penalized === true || message.speed_penalized > 80;});
        this.turned = messages.result.filter((message: { status: string; }) => message.status === 'turned');
        console.log(this.turned);

      }
    });
    this.last_update = new Date();
    this.cd.detectChanges();
  }

}
